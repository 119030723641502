import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { id: "list-delivery-item" }
const _hoisted_2 = { class: "grid grid-cols-2 gap-2 lg:gap-6 md:gap-6" }
const _hoisted_3 = { class: "container-text" }
const _hoisted_4 = { class: "font-title" }
const _hoisted_5 = { class: "font-subTitle" }
const _hoisted_6 = { class: "container-text" }
const _hoisted_7 = { class: "font-title" }
const _hoisted_8 = { class: "font-subTitle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("section", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.data, (item, index) => {
        return (_openBlock(), _createBlock("div", {
          class: "card-delivery cursor-pointer",
          key: index,
          onClick: ($event: any) => (_ctx.openReportType(item.routeName)),
          style: `background-image: url('${item.assets}')`
        }, [
          _createVNode("div", _hoisted_3, [
            _createVNode("p", _hoisted_4, _toDisplayString(item.judul), 1),
            _createVNode("p", _hoisted_5, _toDisplayString(item.description), 1)
          ])
        ], 12, ["onClick"]))
      }), 128)),
      (_ctx.activeUser.roleId === _ctx.direksiId || _ctx.activeUser.roleName.toUpperCase() === 'DIREKSI')
        ? (_openBlock(), _createBlock("div", {
            key: 0,
            class: "col-span-2 card-delivery cursor-pointer",
            style: `background-image: url('${_ctx.direksiCard.assets}')`,
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.goDashboardMetabase && _ctx.goDashboardMetabase(...args)))
          }, [
            _createVNode("div", _hoisted_6, [
              _createVNode("p", _hoisted_7, _toDisplayString(_ctx.direksiCard.judul), 1),
              _createVNode("p", _hoisted_8, _toDisplayString(_ctx.direksiCard.description), 1)
            ])
          ], 4))
        : _createCommentVNode("", true)
    ])
  ]))
}